<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    @view="handleRestore"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #status="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div id="reports-status" :class="{ draft: value === 'Draft' }">
          <BaseIcon name="mdi-circle" size="8px" inherit-color />

          <div class="q-ml-sm">{{ value }}</div>
        </div>
      </div>
    </template>

    <template #entries="{ column, value, item }">
      <div class="stat" @click.stop="$emit('entries', item.id)">
        <div class="label">{{ column }}</div>

        <q-space />

        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "ReportsGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleRestore() {
      this.$router.push({
        name: "reports-list",
      });
    },

    handleMore() {
      this.$emit("more");
    },

    handleSelect(formId) {
      this.$emit("select", formId);
    },

    handleEdit(formId) {
      this.$emit("edit", formId);
    },

    handleDelete(formId) {
      this.$emit("delete", formId);
    },
  },
};
</script>

<style lang="scss" scoped>
#reports-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}

.form-entries {
  text-decoration: underline;
  color: var(--secondary);
}
</style>
