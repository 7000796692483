<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #avatar="{ item }">
      <img
        v-if="item.avatar"
        :src="item.avatar"
        alt="avatar"
        class="user-avatar"
      />
    </template>

    <template #title="{ item }">
      <div class="title">
        {{ item.firstName + " " + item.lastName }}
      </div>
    </template>

    <template #groups="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div>
          {{ appendZeroIfNeeded(value.length) || "-" }}
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "UserGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    appendZeroIfNeeded(count) {
      if (count === 0) {
        return 0;
      }
      return count < 10 ? `0${count}` : count;
    },

    handleSelect(userId) {
      this.$emit("select", userId);
    },

    handleEdit(userId) {
      this.$emit("edit", userId);
    },

    handleDelete(userId) {
      this.$emit("delete", userId);
    },

    handleRestore(userId) {
      this.$emit("restore", userId);
    },

    handleMore(userId) {
      this.$emit("more", userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  height: 36px;
  width: 36px;
  border-radius: 100%;
}
</style>
