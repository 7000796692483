<template>
  <div id="reports-browse">
    <Portal to="action-bar">
      <ItemActions
        v-if="!isLoading"
        item="reports"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        @search="searchRow"
      />
    </Portal>
    <div class="content">
      <template v-if="totalItems">
        <ReportsGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="reportData"
          :columns="columns"
          @edit="editReports"
          @more="addReports"
          @delete="deleteReports"
        />

        <ReportsList
          v-if="activeView === 'LIST'"
          v-model="isReportsDetailsVisible"
          :mode="mode"
          :items="reportData"
          :columns="columns"
        />

        <ReportsDetails
          v-model="isFormDetailsVisible"
          :mode="mode"
          :columns="columns"
          :items="reportData"
        />
        <ConfirmDeleteReports v-model="isDeleteConfirmationVisible" />

        <ConfirmRestoreReports v-model="isRestoreConfirmationVisible" />
      </template>
      <template v-else>
        <StateWrapper
          icon="eva-pie-chart-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DataType from "@/constants/data-type.js";
import ReportsGrid from "./components/ReportsGrid.vue";
import ReportsList from "./components/ReportsList.vue";
import ReportsDetails from "./components/ReportsDetails.vue";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ConfirmDeleteReports from "./components/ConfirmDeleteReports.vue";
import ConfirmRestoreReports from "./components/ConfirmRestoreReports.vue";

export default {
  name: "ReportsBrowseAndTrash",

  components: {
    ReportsGrid,
    ReportsList,
    Portal,
    StateWrapper,
    ItemActions,
    ReportsDetails,
    ConfirmDeleteReports,
    ConfirmRestoreReports,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "type",
          label: "Type",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "published",
          label: "Published",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "draft",
          label: "Draft",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
      ],
      reportData: [
        {
          key: "",
          data: [
            {
              id: this.$nano.id(),
              name: "Single report",
              description: "Report on a single object such...",
              type: "Single",
              published: "2",
              draft: "0",
              icon: "mdi-text-box",
            },
            {
              id: this.$nano.id(),
              name: "Custom Report builder",
              description: "Report on a single object such...",
              type: "Custom",
              published: "1",
              draft: "0",
              icon: "mdi-text-box",
            },
            {
              id: this.$nano.id(),
              name: "Attributions",
              description: "Report on a single object such...",
              type: "Attributions",
              published: "5",
              draft: "1",
              icon: "mdi-text-box",
            },
            {
              id: this.$nano.id(),
              name: "Funnels",
              description: "Report on a single object such...",
              type: "Funnels",
              published: "3",
              draft: "0",
              icon: "mdi-text-box",
            },
          ],
        },
      ],
      filterBy: [],
      isFormDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      activeView: "GRID",
      totalItems: 0,
    };
  },

  created: {
    row_classes(item) {
      if (item.type === "Custom") {
        return "orange";
      }
    },
  },
  methods: {
    /* ... */
    updateFilterBy(filterBy) {
      this.$emit("filter", filterBy);
    },

    addReports() {
      this.isFormDetailsVisible = true;
    },
    editReports() {
      this.$router.push({
        name: "reports-builder",
      });
    },
    deleteReports() {
      this.isDeleteConfirmationVisible = true;
    },
    handleRestore() {
      this.$router.push({
        name: "reports-list",
      });
    },
    row_classes(item) {
      if (item.type === "Custom") {
        return "orange";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#reports-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}

.disablegrid {
  pointer-events: none;
  opacity: 0.4;
}
.wrapper {
  cursor: not-allowed;
}
.orange {
  background-color: orange;
}

.form-entries {
  text-decoration: underline;
  color: var(--secondary);
}
</style>
