<template>
  <ItemDetails :value="value" :history="history" :overview="overview">
    <template #title>Reports Details</template>

    <template #groups="{ value: groups }">
      <ol v-if="groups && groups.length">
        <li v-for="group in groups" :key="group.id">
          {{ group.value }}
        </li>
      </ol>

      <div v-else>-</div>
    </template>
  </ItemDetails>
</template>

<script>
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "ReportsDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      history: [],
      overview: [],
      columns: [],
    };
  },
};
</script>

<style lang="scss" scoped>
ol {
  padding-left: 16px;
  margin: 0px;

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
