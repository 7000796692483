<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #avatar="{ item }">
      <div class="row items-center">
        <img
          v-if="item.avatar"
          :src="item.avatar"
          alt="avatar"
          class="user-avatar"
        />
      </div>
    </template>

    <template #titleHead> Name </template>

    <template #title="{ item }">
      <span class="inline-block font-semibold q-mr-xl">
        {{ item.firstName + " " + item.lastName }}
      </span>
    </template>

    <template #groups="{ value }">
      <div>
        {{ appendZeroIfNeeded(value.length) || "-" }}
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "UserList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    appendZeroIfNeeded(count) {
      if (count === 0) {
        return 0;
      }
      return count < 10 ? `0${count}` : count;
    },

    handleSelect(userId) {
      this.$emit("select", userId);
    },

    handleEdit(userId) {
      this.$emit("edit", userId);
    },

    handleDelete(userId) {
      this.$emit("delete", userId);
    },

    handleRestore(userId) {
      this.$emit("restore", userId);
    },

    handleMore(userId) {
      this.$emit("more", userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  height: 36px;
  width: 36px;
  border-radius: 100%;
}
</style>
