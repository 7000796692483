<template>
  <ItemDetails
    :value="value"
    :history="history"
    :overview="overview"
    @edit="handleEdit"
    @input="handleInput"
    @delete="handleDelete"
  >
    <template #title>Profile Details</template>

    <template #groups="{ value: groups }">
      <ol v-if="groups && groups.length">
        <li v-for="group in groups" :key="group.id">
          {{ group.value }}
        </li>
      </ol>

      <div v-else>-</div>
    </template>
  </ItemDetails>
</template>

<script>
import { user } from "@/api/factory.js";
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "ProfileDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      history: [],
      userList: [],
    };
  },

  computed: {
    overview() {
      var users = this.userList.filter((i) =>
        this.user["usersList"].includes(i.value)
      );
      var email = [];
      users.forEach((user) => {
        email.push(user.label);
      });

      var data = this.columns.reduce((ids, column) => {
        if (column.name !== "users" && column.name !== "menu") {
          ids.push({
            id: column.id,
            name: column.name,
            label: column.label,
            value:
              column.label === "UsersList"
                ? email.toString()
                : this.user[column.name],
          });
        }

        return ids;
      }, []);

      return data;
      // return this.columns.map((column) => ({
      //   id: column.id,
      //   name: column.name,
      //   label: column.label,
      //   value: this.user[column.name],
      // }));
    },
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler() {
        if (this.user.id) {
          // this.getUserHistory();
          this.getUsers();
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleEdit() {
      this.$emit("input", false);
      this.$emit("edit", this.user.id);
    },

    handleDelete() {
      this.$emit("input", false);
      this.$emit("delete", this.user.id);
    },

    /* api functions */

    async getUserHistory() {
      this.isLoading = true;

      const { error, payload } = await user.getUserHistory(this.user.id);

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.history = payload;
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: String(user.id),
          });
        });
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
ol {
  padding-left: 16px;
  margin: 0px;

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
