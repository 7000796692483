<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #status="{ value }">
      <div id="report-status" :class="{ draft: value === 'Draft' }">
        <BaseIcon name="mdi-circle" size="8px" inherit-color />

        <div class="q-ml-sm">{{ value }}</div>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "ReportsList",

  components: { ItemList },

  props: {
    mode: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterBy: [],
    };
  },

  methods: {
    handleEdit() {
      this.$router.push({
        name: "reports-builder",
      });
    },
    handleSelect() {
      this.$emit("select");
    },

    handleDelete() {
      this.$emit("delete");
    },

    handleRestore() {
      this.$emit("restore");
    },

    handleMore() {
      this.$emit("more");
    },
  },
};
</script>

<style lang="scss" scoped>
#report-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}
</style>
