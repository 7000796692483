<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Restore User?</template>

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to restore the user <b>"{{ name }}"</b>. If you
          restore the user, the user will be able to use the ezofis app.
        </p>

        <p>You can always delete the restored user from the browse page.</p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="orange"
        @click="handleNo"
      />
      <BaseButton label="yes" color="orange" @click="handleYes" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmRestoreUser",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    name() {
      return this.user.firstName + " " + this.user.lastName;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("restore", { isDeleted: false });
    },
  },
};
</script>

<style lang="scss" scoped></style>
